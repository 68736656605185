@use '../../common/base';
@use '../../common/variables';

.contagem-icon {
    height: 30px;
    width: 30px;
}
.contagem-od {
    background-color: purple;
    box-shadow: 0 0 2px 2px rgba($color: purple, $alpha: 0.7);
}
.contagem-icon > div {
    color: white;
    margin-left: 5px;
    font-weight: bold;
}
.contagem-icon > img {
    margin-left: 8px;
    margin-top: -10px;
}